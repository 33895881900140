/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'medical-specialty': {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48',
    data: '<path pid="0" d="M22.5 35.5h3v-6h6v-3h-6v-6h-3v6h-6v3h6zM7 44q-1.2 0-2.1-.9Q4 42.2 4 41V14.35q0-1.2.9-2.1.9-.9 2.1-.9h9.5V7q0-1.2.9-2.1.9-.9 2.1-.9h9q1.2 0 2.1.9.9.9.9 2.1v4.35H41q1.2 0 2.1.9.9.9.9 2.1V41q0 1.2-.9 2.1-.9.9-2.1.9zm12.5-32.65h9V7h-9zM7 41h34V14.35H7V41zm0-26.65V41z"/>'
  }
})
